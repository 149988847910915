<template>
  <router-link
    :to="{name: 'whats-on-post', params: {slug: post.slug, categorySlug: post.category.slug}}"
    class="card"
    :style="{backgroundColor: post.card.colour}"
  >
    <div v-if="post.card.image" class="image" :style="{backgroundImage: `url(${post.card.image.url})`}"/>
    <div class="info">
      <p class="category">{{ post.category.name }}</p>
      <p class="title">{{ post.card.title }}</p>
    </div>
  </router-link>
</template>

<script>
  export default {
    props: {
      post: Object
    }
  }
</script>

<style lang="scss" scoped>
  @import 'foundation-sites/scss/foundation.scss';
  @import '@/assets/scss/_settings';
  .card {
    display: flex;
    position: relative;
    height: 100%;
  }
  .image {
    padding-top: 95%;
    width: 100%;
    background-size: contain;
    background-position: bottom center;
    background-repeat: no-repeat;
  }
  .info {
    position: absolute;
    top: vw-relative(20, 320);
    left: vw-relative(20, 320);
    right: vw-relative(20, 320);
    color: #fff;
    text-transform: uppercase;
    @include breakpoint(medium) {
      top: vw-relative(50);
      left: vw-relative(50);
      right: vw-relative(50)
    }
  }
  .category {
    font: bold #{vw-relative(16, 320)}/1 Brown;
    margin-bottom: .75em;
    @include breakpoint(medium) {
      font-size: vw-relative(20);
    }
  }
  .title {
    font: 300 #{vw-relative(24, 320)}/1.1 Brown;
    @include breakpoint(medium) {
      font-size: vw-relative(30);
    }
    &:after {
      content: '';
      vertical-align: middle;
      margin-top: -.1em;
      display: inline-block;
      background-image: url('~@/assets/images/arrow-white.svg');
      background-position: bottom center;
      background-size: contain;
      background-repeat: no-repeat;
      transform: rotate(-90deg);
      width: .8em;
      height: .8em;
    }
  }
</style>
