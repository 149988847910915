import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/our-story',
    name: 'our-story',
    component: () => import('@/views/OurStory.vue')
  },
  {
    path: '/where-to-buy',
    name: 'where-to-buy',
    component: () => import('@/views/WhereToBuy.vue')
  },
  {
    path: '/wines/:slug',
    name: 'wine-category',
    component: () => import('@/views/WineCategory.vue'),
    props: true
  },
  {
    path: '/wines/:categorySlug/:slug',
    name: 'wine',
    component: () => import('@/views/Wine.vue'),
    props: true
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-conditions',
    component: () => import('@/views/Legal.vue'),
    props: {
      slug: 'terms-and-conditions'
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/views/Legal.vue'),
    props: {
      slug: 'privacy-policy'
    }
  },
  {
    path: '/black-tower-b-and-chocolate-giveaway',
    name: 'black-tower-b-and-chocolate-giveaway',
    component: () => import('@/views/Legal.vue'),
    props: {
      slug: 'black-tower-b-and-chocolate-giveaway'
    }
  },
  {
    path: '/legal/:slug',
    name: 'legal',
    component: () => import('@/views/Legal.vue'),
    props: true
  },
  {
    path: '/whats-on',
    name: 'whats-on',
    component: () => import('@/views/WhatsOn.vue'),
  },
  {
    path: '/whats-on/:categorySlug/:slug',
    name: 'whats-on-post',
    component: () => import('@/views/WhatsOnPost.vue'),
    props: true
  },
  {
    path: '/promotion',
    name: 'promotion',
    component: () => import('@/views/Promotion.vue'),
    props: {
      slug: 'promotion'
    }
  },
  {
    path: '/black-tower-cash-prize-promo-terms-and-conditions',
    name: 'black-tower-cash-prize-promo-terms-and-conditions',
    component: () => import('@/views/Legal.vue'),
    props: {
      slug: 'black-tower-cash-prize-promo-terms-and-conditions'
    }
  },
  {
    path: '/black-tower-wines-luna-cinema-promotion',
    name: 'black-tower-wines-luna-cinema-promotion',
    component: () => import('@/views/Legal.vue'),
    props: {
      slug: 'black-tower-wines-luna-cinema-promotion'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
