<template>
  <img :src="image.url" :width="image.width" :height="image.height">
</template>

<script>
  export default {
    props: {
      image: Object
    }
  }
</script>
