<template>
  <div id="app">
    <navigation/>
    <navigation v-if="navigationPadding" :pad-clone="true"/>
    <transition name="fade">
      <router-view/>
    </transition>
    <v-footer/>
    <age-gate v-if="!ageGateConfirmed"/>
  </div>
</template>

<script>
  import Navigation from '@/components/Navigation.vue';
  import VFooter from '@/components/footer/Footer.vue';
  import AgeGate from '@/views/AgeGate.vue';
  import { mapState, mapMutations } from 'vuex';
  export default {
    components: {
      Navigation,
      VFooter,
      AgeGate
    },
    metaInfo: {
      title: 'Black Tower',
      titleTemplate: '%s | Black Tower'
    },
    mounted() {
      this.checkAgeGate();
    },
    methods: {
      ...mapMutations(['checkAgeGate'])
    },
    computed: {
      ...mapState(['navigationPadding', 'ageGateConfirmed'])
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/_settings';

  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.3s;
    transition-property: opacity;
    transition-timing-function: ease;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0
  }
</style>
