import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/scss/app.scss'
import store from './store'

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import VeeValidate from 'vee-validate'
Vue.use(VeeValidate)

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
