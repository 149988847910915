<template>
  <div class="full-height-container-medium banner">
    <v-image class="image" :image="banner.image"/>
    <div class="content">
      <v-image class="bottle" :image="banner.bottle"/>
      <p class="text" v-html="banner.text"/>
    </div>
  </div>
</template>

<script>
  import VImage from '@/components/Image.vue';
  export default {
    props: {
      banner: Object
    },
    components: {
      VImage
    }
  }
</script>

<style lang="scss" scoped>
  @import 'foundation-sites/scss/foundation.scss';
  @import '@/assets/scss/_settings';
  .banner {
    position: relative;
    display: flex;
    align-items: flex-end;
  }
  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  .bottle {
    height: auto;
    width: 10%;
    margin: vw-relative(70 10 20 20, 320);
    @include breakpoint(medium) {
      margin: 0 vw-relative(60 20 120);
    }
  }
  .content {
    display: flex;
    align-items: flex-end;
    flex: 1;
  }
  .text {
    color: #FFF;
    font: 300 #{vw-relative(22, 320)}/1 Brown;
    margin-bottom: vw-relative(40, 320);
    text-transform: uppercase;
    @include breakpoint(medium) {
      font-size: vw-relative(70);
      margin-bottom: vw-relative(60);
    }
  }
</style>
