<template>
  <div class="card" :style="{color: category.textColour}" ref="cards">
    <div class="category">{{ category.name }}</div>
    <div class="description" :class="{'-small-print': category.banner.smallPrint}" v-html="category.banner.description"/>
    <div v-if="category.banner.smallPrint" class="small-print" v-html="category.banner.smallPrint"/>
    <router-link
      v-if="withLink"
      class="link"
      :to="{ name: 'wine-category', params: {slug: category.slug} }"
      :style="{backgroundColor: category.textColour}"
    >{{ category.banner.buttonText }}</router-link>
  </div>
</template>

<script>
  export default {
    props: {
      category: Object,
      withLink: Boolean
    }
  }
</script>

<style lang="scss" scoped>
  @import 'foundation-sites/scss/foundation.scss';
  @import '@/assets/scss/_settings';
  .card {
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .category {
    font: bold #{vw-relative(12, 320)}/1 Brown;
    text-transform: uppercase;
    color: inherit;
    margin-bottom: .8em;
    @include breakpoint(medium) {
      font-size: vw-relative(24);
    }
  }
  .description {
    font: 100 #{vw-relative(19, 320)}/1 Brown;
    text-transform: uppercase;
    color: inherit;
    margin-bottom: .8em;
    @include breakpoint(medium) {
      font-size: vw-relative(75);
    }
    &.-small-print {
      margin-bottom: 0;
    }
  }
  .small-print {
    font: 300 #{vw-relative(10, 320)}/1 Brown;
    text-transform: uppercase;
    color: inherit;
    margin-bottom: .5em;
    @include breakpoint(medium) {
      margin-bottom: 1.6em;
      font-size: vw-relative(20);
    }
  }
  .link {
    position: absolute;
    top: 100%;
    color: #FFF;
    width: 65%;
    font: #{vw-relative(12, 320)}/1 Brown;
    text-transform: uppercase;
    text-decoration: none;
    padding: .3em .4em .2em .5em;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    @include breakpoint(medium) {
      width: 35%;
      padding: .7em .8em .6em .9em;
      font-size: vw-relative(18);
    }
    &:after {
      content: '';
      display: inline-block;
      margin-top: -.15em;
      width: .8em;
      height: .4em;
      transform: rotate(-90deg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url('~@/assets/images/arrow-white.svg');
      animation: arrow-bob .4s infinite alternate;
      animation-play-state: paused;
      @keyframes arrow-bob {
        0% {
          transform: rotate(-90deg) translateY(0);
        }
        100% {
          transform: rotate(-90deg) translateY(.5em);
        }
      }
    }
    &:hover:after {
      animation-play-state: running;
    }
  }
</style>
