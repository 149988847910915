<template>
  <div v-if="instagramData.username">
    <a class="link" :href="instagramData.profileLink" target="_blank">Follow us on @{{ instagramData.username }}</a>
    <div class="media-items">
      <a
        class="media-item"
        v-for="media in instagramData.media"
        :key="media.media_id"
        :href="media.permalink"
        target="_blank"
      >
        <div class="media-item-wrapper">
          <img class="media" :src="media.media_url" alt="">
        </div>
      </a>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex';

  export default {
    mounted() {
      this.fetchInstagramData()
        .then(() => {
        })
    },
    methods: {
      ...mapActions(['fetchInstagramData']),
    },
    computed: {
      ...mapState(['instagramData'])
    }
  }
</script>

<style lang="scss" scoped>
  @import 'foundation-sites/scss/foundation.scss';
  @import '@/assets/scss/_settings';
  .link {
    display: block;
    padding: .4em;
    font: #{vw-relative(14, 320)}/1 Brown;
    background-color: #EF7997;
    color: #FFF;
    text-decoration: none;
    text-transform: uppercase;
    @include breakpoint(medium) {
      margin-top: .8em;
      display: inline-block;
      font-size: vw-relative(16);
      padding: .8em 1.8em .8em 3em;
    }
  }
  .media-items {
    display: flex;
  }
  .media-item {
    display: block;
    flex: 1 0 0;
    @include breakpoint(small only) {
      &:last-child {
        display: none;
      }
    }
  }
  .media-item-wrapper {
    padding-top: 100%;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 6%;
      right: 3%;
      width: 16px;
      height: 16px;
      background-image: url('~@/assets/images/instagram-white.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      @include breakpoint(medium) {
        width: 28px;
        height: 28px;
      }
    }
  }
  .media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
</style>
