<template>
  <div v-if="page">
    <banner :banner="page.banner"/>
    <div class="show-for-medium">
      <wine-categories :categories="wineCategories" v-if="wineCategories.length"/>
    </div>
    <div class="hide-for-medium" v-if="wineCategories.length">
      <wine-category-banner
        v-for="category in wineCategories"
        :category="category"
        :key="'category' + category.id"
        :with-link="true"
      />
    </div>
    <div class="grid-x">
      <div
        v-for="post in page.posts"
        :key="post.id"
        class="cell medium-4"
      >
        <whats-on-card :post="post"/>
      </div>
    </div>
    <instagram-feed/>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import Banner from '@/components/home/Banner.vue';
  import WineCategories from '@/components/WineCategories.vue';
  import InstagramFeed from '@/components/home/InstagramFeed.vue';
  import WineCategoryBanner from '@/components/WineCategoryBanner.vue';
  import WhatsOnCard from '@/components/WhatsOnCard';

  export default {
    metaInfo: {
      title: 'Black Tower',
      titleTemplate: null
    },
    components: {
      Banner,
      WineCategories,
      InstagramFeed,
      WineCategoryBanner,
      WhatsOnCard
    },
    mounted() {
      this.fetchPage('home')
        .then(() => {
          this.fetchWineCategories()
            .then(() => {
              this.$nextTick(() => {
                this.$root.$emit('update:scrollTrigger');
              })
            });
        });
    },
    destroyed() {
      this.$root.$off('update:scrollTrigger');
    },
    methods: {
      ...mapActions([
        'fetchPage',
        'fetchWineCategories'
      ]),
    },
    computed: {
      ...mapState(['wineCategories']),
      page() {
        return this.$store.getters.getPage('home');
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
