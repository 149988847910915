import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import Cookies from 'js-cookie'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const baseUrl = process.env.NODE_ENV === 'production'
                ? window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + process.env.VUE_APP_API_BASE
                : process.env.VUE_APP_API_BASE;

export default new Vuex.Store({
  state: {
    pages: [],
    sites: [],
    wineCategories: [],
    fetchingWineCategories: false,
    instagramData: {},
    wineList: [],
    navigationPadding: false,
    ageGateConfirmed: false,
    postList: [],
    postListPage: 0,
    postListPageMax: 1,
    posts: []
  },
  strict: debug,
  getters: {
    getPage: (state) => (slug) => {
      return state.pages.find(page => page.slug === slug)
    },
    getWineCategory: (state) => (slug) => {
      return state.wineCategories.find(category => category.slug === slug)
    },
    getWineList: (state) => (categorySlug) => {
      return state.wineList.filter(wine => wine.category.slug === categorySlug)
    },
    getWine: (state) => (slug, categorySlug) => {
      return state.wineList.find(wine => wine.category.slug === categorySlug && wine.slug === slug)
    },
    getPost: (state) => (slug) => {
      return state.posts.find(post => post.slug === slug)
    },
  },
  mutations: {
    setPage(state, page) {
      state.pages.push(page);
    },
    setSites(state, sites) {
      Vue.set(state, 'sites', sites);
    },
    setWineCategories(state, wineCategories) {
      Vue.set(state, 'wineCategories', wineCategories);
    },
    setFetchingWineCategories(state) {
      state.fetchingWineCategories = true;
    },
    setInstagramData(state, instagramData) {
      Vue.set(state, 'instagramData', instagramData);
    },
    setWineList(state, wines) {
      state.wineList = state.wineList.concat(wines);
    },
    setNavigationPadding(state, pad = true) {
      state.navigationPadding = pad;
    },
    confirmAgeGate(state) {
      state.ageGateConfirmed = true;
      Cookies.set('age-gate', true, {expires: 365});
    },
    checkAgeGate(state) {
      if (Cookies.get('age-gate')) {
        state.ageGateConfirmed = true;
      }
    },
    setPosts(state, {data}) {
      state.postListPageMax = data.pages;
      state.postList = state.postList.concat(data.posts);
      state.posts = state.posts.concat(data.posts);
    },
    incrementPostListPage(state) {
      state.postListPage++;
    },
    setPost(state, {data}) {
      state.posts.push(data);
    },
    setPostNavigation(state, {slug, data}) {
      state.posts.find(post => {
        if (post.slug === slug) {
          Vue.set(post, 'navigation', data);
          return true;
        }
        return false;
      })
    },
  },
  actions: {
    fetchPage({getters, commit}, args) {
      let slug = args;
      /* eslint-disable no-unused-vars */
      let template = false;
      /* eslint-enable no-unused-vars */
      if (typeof args == 'object') {
        slug = args.slug;
        template = args.template;
      }
      if (getters.getPage(slug)) {
        return new Promise((resolve) => {
          resolve(getters.getPage(slug));
        })
      } else {
        return axios.get(baseUrl + 'page/' + slug + (template ? '?template=' + template: '') )
          .then(({data}) => {
            commit('setPage', data);
            return data;
          });
      }
    },
    fetchSites({state, commit}) {
      if (state.sites.length > 0) {
        return new Promise((resolve) => {
          resolve();
        })
      } else {
        return axios.get(baseUrl + 'sites')
          .then(({data}) => {
            commit('setSites', data);
          });
      }
    },
    fetchWineCategories({state, commit}) {
      if (state.wineCategories.length > 0 || state.fetchingWineCategories) {
        return new Promise((resolve) => {
          resolve();
        })
      } else {
        commit('setFetchingWineCategories');
        return axios.get(baseUrl + 'wines/categories')
          .then(({data}) => {
            commit('setWineCategories', data);
          });
      }
    },
    fetchInstagramData({state, commit}) {
      if (state.instagramData.media && state.instagramData.media.legnth > 0) {
        return new Promise((resolve) => {
          resolve();
        })
      } else {
        return axios.get(baseUrl + 'instagram')
          .then(({data}) => {
            commit('setInstagramData', data);
          });
      }
    },
    fetchWineList({getters, commit}, categorySlug) {
      if (getters.getWineList(categorySlug).length > 0) {
        return new Promise((resolve) => {
          resolve();
        })
      } else {
        return axios.get(baseUrl + 'wines/list/' + categorySlug)
          .then(({data}) => {
            commit('setWineList', data);
          });
      }
    },
    fetchPosts({state, commit}) {
      commit('incrementPostListPage');
      if (state.postListPage > state.postListPageMax) {
        return new Promise((resolve) => {
          resolve();
        })
      } else {
        return axios.get(process.env.VUE_APP_API_BASE + 'post?page=' + state.postListPage )
          .then(({data}) => {
            commit('setPosts', {data});
          });
      }
    },
    fetchPost({getters, commit}, slug) {
      if (getters.getPost(slug)) {
        return new Promise((resolve) => {
          resolve(getters.getPost(slug));
        })
      } else {
        return axios.get(process.env.VUE_APP_API_BASE + 'post/' + slug )
          .then(({data}) => {
            commit('setPost', {data});
          });
      }
    },
    fetchPostNavigation({getters, commit}, slug) {
      if (getters.getPost(slug).navigation) {
        return new Promise((resolve) => {
          resolve();
        })
      } else {
        return axios.get(process.env.VUE_APP_API_BASE + 'post/navigation/' + slug )
          .then(({data}) => {
            commit('setPostNavigation', {slug, data});
          });
      }
    },
  }
})
