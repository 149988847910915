<template>
  <div class="full-height-container-medium grid-x container" ref="container">
    <div
      class="bottle"
      :class="{['-' + category.packagingType]: true}"
      :style="{
        backgroundImage: `url(${category.bottle.url})`
      }"
    />
    <div class="cell small-6">
      <div class="images">
        <v-image class="image" :image="category.banner.image" ref="images"/>
      </div>
    </div>
    <div class="cell small-6 card-container" :class="{'-without-link': !withLink}" ref="cardContainer" :style="{backgroundColor: category.colour}">
      <wine-category-card :category="category" :with-link="withLink"/>
    </div>
  </div>
</template>

<script>
  import VImage from '@/components/Image.vue';
  import WineCategoryCard from '@/components/WineCategoryCard.vue';

  export default {
    props: {
      category: Object,
      withLink: Boolean
    },
    components: {
      VImage,
      WineCategoryCard
    }
  }
</script>

<style lang="scss" scoped>
  @import 'foundation-sites/scss/foundation.scss';
  @import '@/assets/scss/_settings';
  .container {
    position: relative;
    overflow: hidden;
  }
  .bottle {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 13%;
    height: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
    transform: translate(-50%, 10%);
    &.-box {
      width: 23%;
      left: 45%;
      transform: translate(-50%, 8%);
    }
  }
  .images {
    position: relative;
    width: 100%;
    @include breakpoint(small only) {
      padding-top: 100%;
    }
    @include breakpoint(medium) {
      height: 100%;
    }
  }
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  .card-container {
    position: relative;
    display: flex;
    align-items: center;
    padding: vw-relative(20 0 20 26, 320);
    @include breakpoint(medium) {
      padding: vw-relative(134 0 134 134);
    }
    &.-without-link {
      @include breakpoint(small only) {
        align-items: flex-end;
      }
    }
  }
</style>
