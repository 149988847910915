<template>
  <div class="full-height-container grid-x container" ref="container">
    <div
      v-for="category in categories"
      :key="category.id"
      class="bottle"
      :class="{['-' + category.packagingType]: true}"
      ref="bottles"
      :style="{
        backgroundImage: `url(${category.bottle.url})`
      }"
    />
    <div class="cell small-6">
      <div class="images">
        <v-image v-for="category in categories" :key="category.id" class="image" :image="category.banner.image" ref="images"/>
      </div>
    </div>
    <div class="cell small-6 card-container" ref="cardContainer" :style="{backgroundColor: categories[0].colour}">
      <wine-category-card
        ref="cards"
        v-for="category in categories"
        :key="category.id"
        :with-link="true"
        :category="category"
      />
    </div>
  </div>
</template>

<script>
  import VImage from '@/components/Image.vue';
  import WineCategoryCard from '@/components/WineCategoryCard.vue';
  import { gsap } from 'gsap';

  export default {
    props: {
      categories: Array
    },
    components: {
      VImage,
      WineCategoryCard
    },
    data() {
      return {
        tl: null
      }
    },
    mounted() {
      this.refresh();
      this.$root.$on('update:scrollTrigger', this.refresh);
    },
    destroyed() {
      if (this.tl) {
        this.tl.scrollTrigger.kill();
        this.tl.kill();
      }
    },
    methods: {
      refresh() {
        if (!this.tl) {
          this.tl = gsap.timeline({
            scrollTrigger: {
              pin: true,
              start: 'top top',
              end: '300%',
              trigger: this.$refs.container,
              scrub: true
            }
          });
          this.categories.forEach((category, i) => {
            if (category.packagingType == 'box') {
              this.tl.fromTo(this.$refs.bottles[i], {yPercent: 100}, {yPercent: 8});
            } else {
              this.tl.fromTo(this.$refs.bottles[i], {yPercent: 100}, {yPercent: 10});
            }
            if (i !== 0) {
              this.tl.fromTo(this.$refs.images[i].$el, {autoAlpha: 0}, {autoAlpha: 1}, "<");
              this.tl.to(this.$refs.cardContainer, {backgroundColor: category.colour, duration: .5}, "<");
              this.tl.fromTo(this.$refs.cards[i].$el, {autoAlpha: 0, yPercent: 10}, {autoAlpha: 1, yPercent: 0}, "<");
            }
            if (this.categories.length !== i+1) {
              this.tl.to(this.$refs.bottles[i], {yPercent: -100, delay: 1});
              this.tl.to(this.$refs.cards[i].$el, {autoAlpha: 0, yPercent: -10}, "<");
            }
          });

        } else {
          this.tl.scrollTrigger.refresh();
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import 'foundation-sites/scss/foundation.scss';
  @import '@/assets/scss/_settings';
  .container {
    position: relative;
    overflow: hidden;
  }
  .bottle {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 13%;
    height: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
    transform: translateX(-50%);
    &.-box {
      width: 23%;
      left: 45%;
    }
  }
  .images {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  .card-container {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: vw-relative(134);
  }
</style>
