<template>
  <div class="links">
    <a
      v-for="link in links"
      :href="link.url"
      class="link"
      :class="{[`-${link.name}`]:true}"
      :key="link.name"
      target="_blank"
    />
  </div>
</template>

<script>
  export default {
    data() {
      return {
        links: [
          {
            name: 'instagram',
            url: 'https://www.instagram.com/blacktowerwine/'
          },
          // {
          //   name: 'twitter',
          //   url: 'https://twitter.com/blacktowerwine'
          // },
          {
            name: 'facebook',
            url: 'https://www.facebook.com/BlackTowerWine/'
          }
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import 'foundation-sites/scss/foundation.scss';
  @import '@/assets/scss/_settings';
  .links {
    @include breakpoint(small only) {
      text-align: center;
      padding: 10px 0;
    }
  }
  .link {
    width: 24px;
    height: 24px;
    margin: 0 vw-relative(10, 320);
    display: inline-block;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    @include breakpoint(medium) {
      width: vw-relative(28);
      height: vw-relative(28);
      margin: 0 vw-relative(9);
    }
    &.-instagram {
      background-image: url('~@/assets/images/instagram.svg');
    }
    &.-twitter {
      background-image: url('~@/assets/images/twitter.svg');
    }
    &.-facebook {
      background-image: url('~@/assets/images/facebook.svg');
    }
  }
</style>
