<template>
  <div class="age-gate">
    <div class="overlay"></div>
    <div class="content" :style="{backgroundImage: page && page.background ? `url(${page.background.url})`:null}">
      <div class="grid-x align-middle grid">
        <div class="cell small-2 small-offset-1 show-for-medium">
          <v-image class="image" v-if="page && page.image" :image="page.image"/>
        </div>
        <div class="cell small-12 medium-5 medium-offset-1 content-cell">
          <img class="logo" :class="{'-margin': disagree}" :src="require('@/assets/images/logo.png')" alt="">
          <v-image class="image hide-for-medium" v-if="page && page.image" :image="page.image"/>
          <template v-if="!disagree">
            <p class="text" v-if="page && page.content" v-html="page.content"/>
            <div><button class="button" @click="confirmAgeGate">I Agree</button></div>
            <div><button class="button" @click="disagree = true">I Disagree</button></div>
          </template>
          <template v-else>
            <p class="text" v-if="page && page.disagreeContent" v-html="page.disagreeContent"/>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapMutations } from 'vuex';
  import VImage from '@/components/Image'
  import {disableScroll, enableScroll} from '@/util/toggleScroll'

  export default {
    metaInfo: {
      title: 'Black Tower',
      titleTemplate: null
    },
    data() {
      return {
        disagree: false
      }
    },
    components: {
      VImage
    },
    mounted() {
      disableScroll();
      this.fetchPage('age-gate')
        .then(() => {
        });
    },
    destroyed() {
      enableScroll();
    },
    methods: {
      ...mapActions(['fetchPage']),
      ...mapMutations(['confirmAgeGate'])
    },
    computed: {
      page() {
        return this.$store.getters.getPage('age-gate');
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import 'foundation-sites/scss/foundation.scss';
  @import '@/assets/scss/_settings';
  .age-gate {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: map-get($z-indexes, 'age-gate');
    display: flex;
    align-items: center;
  }
  .overlay {
    background-color: #fff;
    opacity: .8;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .content {
    position: relative;
    max-height: 100%;
    width: 100%;
    overflow: auto;
    background-size: 100% 100%;
    padding: vw-relative(20 20, 320);
    @include breakpoint(medium) {
      padding: vw-relative(60 0);
    }
  }
  .grid {
    // width: 100%;
  }
  .image {
    width: 100%;
    height: auto;
    @include breakpoint(small only) {
      width: vw-relative(200);
      display: inline-block;
    }
  }
  .content-cell {
    text-align: center;
  }
  .logo {
    width: 70px;
    margin: 0 auto 10px;
    @include breakpoint(medium) {
      width: 100px;
    }
    @include breakpoint(large) {
      width: 146px;
      margin: 0 auto 20px;
    }
    &.-margin {
      margin-bottom: 100px;
    }
  }
  .text {
    font: 300 vw-relative(14, 320)/1.2 Brown;
    margin-bottom: 1.2em;
    @include breakpoint(medium) {
      font-size: vw-relative(17);
    }
  }
  .button {
    font: 300 #{vw-relative(14, 320)}/1 Brown;
    text-transform: uppercase;
    background-color: rgba(#FFFFFF, .5);
    border: 1px solid #7961B0;
    padding: .6em 0;
    width: 14em;
    margin-bottom: 1.1em;
    @include breakpoint(medium) {
      padding: 1.1em 0;
      font-size: vw-relative(18);
    }
  }
</style>
